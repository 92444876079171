<template>
    <section
        v-if="statToday"
        class="dashboard__statistics-today">
        
        <div class="wv-block--white-hover dashboard__statistics-today-item">
            <div class="dashboard__statistics-today-item__info">
                <p class="dashboard__statistics-today-item__sum">
                    {{ statToday.hits }}
                </p>
                <p class="dashboard__statistics-today-item__name">
                    {{ $lng.__('lk_webmaster_dashboard', 'Hits') }}
                </p>
            </div>
            <div class="dashboard__statistics-today-item__logo">
                <img
                    src="/images/statistics-hits-logo.svg"
                    alt="Hits"
                />
            </div>
        </div>
        <div class="wv-block--white-hover dashboard__statistics-today-item">
            <div class="dashboard__statistics-today-item__info">
                <p class="dashboard__statistics-today-item__sum">
                    {{ statToday.count_valid }}
                </p>
                <p class="dashboard__statistics-today-item__name">
                    {{ $lng.__('lk_webmaster_dashboard', 'Leads') }}
                </p>
            </div>
            <div class="dashboard__statistics-today-item__logo">
                <img
                    src="/images/statistics-conversions-logo.svg"
                    alt="Valid"
                />
            </div>
        </div>
        <div class="wv-block--white-hover dashboard__statistics-today-item">
            <div>
                <div class="dashboard__statistics-today-item__info">
                    <template v-if="statToday.money_confirmed.length == 0">
                        <p class="dashboard__statistics-today-item__sum">
                            0 EUR
                        </p>
                    </template>
                    <template v-else>
                        <p class="dashboard__statistics-today-item__sum" v-for="(moneyConfirmed, index) in statToday.money_confirmed" :key="index">
                            {{ moneyConfirmed.amount }} {{ moneyConfirmed.currency ? moneyConfirmed.currency : ' EUR'}}
                        </p>
                    </template>
                    <p class="dashboard__statistics-today-item__name">
                        {{ $lng.__('lk_webmaster_dashboard', 'Confirmed') }}
                    </p>
                </div>
            </div>            
            <div class="dashboard__statistics-today-item__logo">
                <img
                    src="/images/statistics-money-confirmed-logo.svg"
                    alt="Money confirmed"
                />
            </div>
            <div
                v-if="$store.getters['webmaster/lastDashboardUpdate']"
                class="dashboard__statistics-today-item__notice">
                {{ $lng.__('lk_webmaster_dashboard', 'Updated') }} {{ $store.getters['webmaster/lastDashboardUpdate'] }}
            </div>
        </div>
        <div class="wv-block--white-hover dashboard__statistics-today-item">
            <div class="dashboard__statistics-today-item__info">
                <p class="dashboard__statistics-today-item__sum">
                    {{ statToday.cr == null ? '0' : statToday.cr }}
                </p>
                <p class="dashboard__statistics-today-item__name">
                    {{ $lng.__('lk_webmaster_dashboard', 'Cr') }}
                </p>
            </div>
            <div class="dashboard__statistics-today-item__logo">
                <img
                    src="/images/statistics-cr-logo.svg"
                    alt="Cr"
                />
            </div>
        </div>
        <div class="wv-block--white-hover dashboard__statistics-today-item">
            <div class="dashboard__statistics-today-item__info">
                <p class="dashboard__statistics-today-item__sum">
                    {{ statToday.count_new }}
                </p>
                <p class="dashboard__statistics-today-item__name">
                    {{ $lng.__('lk_webmaster_dashboard', 'New') }}
                </p>
            </div>
            <div class="dashboard__statistics-today-item__logo">
                <img
                    src="/images/statistics-processing-logo.svg"
                    alt="New"
                />
            </div>
        </div>
        <div class="wv-block--white-hover dashboard__statistics-today-item">
            <div class="dashboard__statistics-today-item__info">
                <p class="dashboard__statistics-today-item__sum">
                    {{ statToday.wvcoin_confirmed }}
                </p>
                <p class="dashboard__statistics-today-item__name">
                    {{ $lng.__('lk_webmaster_dashboard', 'Bonus') }}
                </p>
            </div>
            <div class="dashboard__statistics-today-item__logo">
                <img
                    src="/images/statistics-webcoins-logo.svg"
                    alt="WVCoins"
                />
            </div>
            <div
                v-if="$store.getters['webmaster/lastDashboardUpdate']"
                class="dashboard__statistics-today-item__notice">
                {{ $lng.__('lk_webmaster_dashboard', 'Updated') }} {{ $store.getters['webmaster/lastDashboardUpdate'] }}
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'DashboardStatistics',
        props: [],
        data() {
            return {};
        },
        computed: {
            statToday() {
                return this.$store.state.webmaster.statToday
            },
            lastDashboardUpdate() {
                return this.$store.getters['webmaster/lastDashboardUpdate']
            },
        },
        methods: {},
        mounted() {
            this.$store.dispatch('webmaster/getStatToday');
        }
    };
</script>

<style lang="scss">
    
    @import '../../../scss/utils/mixins.scss';
    @import '../../../scss/utils/variables.scss';
    
    .dashboard__statistics-today {
        @include grid--100;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: space-between;
        order: 1;
        @include break(lg) {
            min-height: fit-content;
        }
        &-item{
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex: none;
            min-height: 120px;
            width: 100%;
            margin-bottom: 10px;
            padding: 20px;
            position: relative;
            @include break(sm){
                width: calc((100% - 10px) / 2);
            }
            @include break(md) {
                width: calc((100% - 20px) / 3);
            }
            @include break(lg) {
                min-height: calc((100% - 10px) / 2);
            }
            &__info {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }
            &__sum {
                margin-bottom: 5px;
                font-weight: 800;
                font-size: 24px;
                line-height: 29px;
                letter-spacing: 0.005em;
                color: $color-day-black;
                @include maxWidth(950) {
                    font-size: 18px;
                    line-height: 22px;
                }
            }
            &__name {
                font-family: $font-secondary;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.5px;
                color: $color-text-muted;
                @include maxWidth(950) {
                    font-size: 14px;
                    line-height: 16px;
                }
            }
    
            &__logo {
                width: 90px;
                height: 90px;
                @include maxWidth(950) {
                    width: 50px;
                    height: 50px;
                }
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }

            &__notice{
                position: absolute;
                left: 20px;
                right: 0;
                text-align: left;
                bottom: 10px;
                font-size: 12px;
                font-family: $font-secondary;
                color: $color-text-muted;
                opacity: .8;
            }
            
        }
    }
</style>