<template>
    <!-- Top offers -->
    <section
        v-if="offers.length"
        class="wv-block--white offers__header-table-block">
        
        <div class="offers__header-table_header">
            <div class="wv-block--header">
                <h3>
                    {{ $lng.__('lk_webmaster_dashboard', 'Top offers') }}
                </h3>
<!--                <div
                    class="wv-hlp"
                    data-tippy-content="Топовые офферы"></div>
                <div class="top-offers__icon offers__header-table_icon"></div>-->
            </div>
            <ToggleViewMode
                :mode="offersViewMode"
                @switch="setOffersViewMode"
            ></ToggleViewMode>
        </div>
        
        <OffersBlock
            :viewMode="offersViewMode"
            :offers="offers"
            @sortByName="swithSortByName"
            :sortByName="sortByName"
        ></OffersBlock>
        
    </section>
    
</template>

<script>
    
    import OffersBlock from '../Offers/OffersBlock.vue';
    import ToggleViewMode from '../../../components/ToggleViewMode';
    
    export default {
        name: 'DashboardTopOffers',
        props: [],
        data() {
            return {
                offersViewMode: 'table',
                sortByName: 'asc',
            }
        },
        computed: {
            offers() {
                return collect(this.$store.getters['webmaster/offersOnlyTop'])
                    .take(4)
                    .all()
            },
            offersSorted() {
                let sortedAsc = this.offers.sort(function(a, b) {
                    return ('' + a.name).localeCompare(b.name);
                })
                return this.sortByName === 'asc' ? sortedAsc : sortedAsc.reverse()
            },
        },
        methods: {
            setOffersViewMode(mode) {
                this.offersViewMode = mode;
            },
            swithSortByName() {
                this.sortByName = this.sortByName === 'asc' ? 'desc' : 'asc';
            }
        },
        beforeMount() {
            this.$store.dispatch('webmaster/getOffers')
        },
        mounted() {
            setTimeout(WV.tippyMount, 3000)
        },
        components: {
            OffersBlock,
            ToggleViewMode,
        }
    };
</script>

<style lang="scss">

</style>