<template>
  <section class="wv-block--white-hover dashboard__blog-articles">

    <Loading v-if="isLoading"></Loading>

    <template v-else>
      <div
        v-if="posts.length"
        class="dashboard__blog-articles_items"
        ref="items"
      >
        <div
          class="dashboard__blog-articles_item"
          v-for="post in posts" :key="post.title"
        >
          <a
            :href="post.link"
            class="dashboard__blog-articles_link"
            target="_blank"
          >
            <div class="dashboard__blog-articles_image">
              <img :src="post.yoast_head_json.og_image[0].url" :alt="post.title">
            </div>
          </a>
          <h4 class="dashboard__blog-articles_title">{{post.title.rendered}}</h4>
        </div>
      </div>
      <div
        class="dashboard__blog-articles_empty"
        v-else
      >
        {{ $lng.__('lk_webmaster_dashboard', 'There is currently no news pinned down.') }}
      </div>
    </template>
    <a
      :href="getBlogUrl"
      target="_blank"
      class="wv-btn--green dashboard__blog-articles_btn"
    >
      {{ $lng.__('lk_webmaster_dashboard', 'All news') }}
    </a>
  </section>
</template>

<script>
export default {
  data() {
    return {
      posts: [],
      itemsToggle: null,
      isLoading: false
    }
  },
  computed: {
    getBlogUrl() {
      if(this.$lng.lng_code === 'ru') return 'https://blog.webvork.com/ru/'
      else return 'https://blog.webvork.com/en/'
    }
  },
  methods: {

    async getPosts() {

      let apiUrl
      
      if(this.$lng.lng_code === 'ru') apiUrl = 'https://blog.webvork.com/wp-json/wp/v2/posts?per_page=3&filter[meta_query][0][key]=into_lk&filter[meta_query][0][value]=yes&filter[lang]=ru'
      else apiUrl = 'https://blog.webvork.com/wp-json/wp/v2/posts?per_page=3&filter[meta_query][0][key]=into_lk&filter[meta_query][0][value]=yes&filter[lang]=en'

      try {
        this.isLoading = true
        const response = await axios.get(apiUrl)
        this.posts = response.data
      }
      catch(error) {
        console.log(`Something went wrong.. Error: ${error}`)
      }
      finally {
        this.isLoading = false
      }

    }
  },
  beforeMount() {
    this.getPosts()
  },
  mounted() {
    let itemCounter = 0
    
    this.itemsToggle = setInterval(() => {
      for(let i = 0; i < this.$refs.items.children.length; i++) {
        this.$refs.items.children[i].style.display = 'none'
      }
      if(itemCounter === this.$refs.items.children.length - 1) itemCounter = 0
      else itemCounter += 1
      this.$refs.items.children[itemCounter].style.display = 'block'
    }, 5000)

  },
  unmounted() {
    clearInterval(this.itemsToggle)
  }
}
</script>

<style lang="scss">
@import '../../../scss/utils/mixins.scss';
@import '../../../scss/utils/variables.scss';

.dashboard__blog-articles {
  padding-bottom: 30px;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
  order: 4;
  @include break(xs) {
      width: 100%;
  }
  @include break(sm) {
      width: 50%;
  }
  @include break(xxl) {
      width: calc(35% - 20px);
  }
  &_items {
    width: 100%;
  }
  &_item {
    width: 100%;
    display: none;
    &:first-child {
      display: block;
    }
  }
  &_image {
    margin-bottom: 15px;
    height: 300px;
    @include break(xl) {
      height: 320px;
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  &_title {
    margin-bottom: 30px;
    padding: 0 30px;
    min-height: 32px;
    text-align: center;
  }
  &_btn {
    max-width: 270px;
    width: 100%;
  }
  &_empty {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    text-align: center;
    flex: 0 0 250px;
    @include break(xxl) {
      flex: 1;
    }
  }
}

</style>