<template>
    <div class="webmaster-layout__content">

        <DashboardMobileBalances></DashboardMobileBalances>

        <div class="dashboard__toprow">

            <DashboardStatistics></DashboardStatistics>

            <div class="dashboard-manager-style">
                <DashboardManager></DashboardManager>
            </div>

            <DashboardChart></DashboardChart>

            <DashboardBlogArticles></DashboardBlogArticles>

<!--            <DashboardNews></DashboardNews>-->

        </div>

        <DashboardTopOffers></DashboardTopOffers>
    </div>
</template>

<script>
import DashboardManager from './DashboardManager.vue';
import DashboardMobileBalances from './DashboardMobileBalances.vue';
import DashboardStatistics from './DashboardStatistics.vue';
import DashboardTopOffers from './DashboardTopOffers.vue';
import DashboardChart from './DashboardChart.vue';
import DashboardNews from './DashboardNews.vue';
import DashboardBlogArticles from './DashboardBlogArticles.vue';

export default {
    name: 'WebmasterDashboard',
    data() {
        return {
            info: null
        }
    },
    beforeCreate() {
    },
    components: {
        DashboardManager,
        DashboardStatistics,
        DashboardTopOffers,
        DashboardChart,
        DashboardNews,
        DashboardMobileBalances,
        DashboardBlogArticles
    }
}
</script>

<style lang="scss">
@import '../../../scss/utils/mixins.scss';
@import '../../../scss/utils/variables.scss';

.dashboard {
    &__toprow {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        gap: 20px;
        @include break(sm) {
            flex-direction: row;
            flex-wrap: wrap;
            align-items: stretch;
        }
        & > section:first-child, & section:nth-child(3) {
            @include break(sm) {
                width: 100%;
            }
            @include break(xxl) {
                width: 65%;
                flex: none;
            }
        }
    }
    &-manager-style {
        width: 100%;
        // margin: 0 0 20px;
        order: 2;
        @include break(md) {
            order: 3;
            // margin: 0 0 20px 0;
        }
        @include break(xxl) {
            margin: 0;
            width: calc(35% - 20px);
            order: 2;
        }
        .dashboard__managers {
            height: fit-content;
            @include break(md) {
                align-items: center;
            }
            @include break(xxl) {
                height: 332px;
            }
        }
        .dashboard__managers_social {
            @include break(md) {
                height: fit-content;
            }
        }

    }
}
</style>
