<template>

    <section
        v-if="isVisible"
        class="wv-block--white-hover dashboard__lead-statistics-block">

        <div class="wv-block--header DashboardChart__head">

            <div class="DashboardChart__head__title">
                <h3>{{ $lng.__('lk_webmaster_dashboard', 'Lead statistics') }}</h3>
                <div
                    class="wv-hlp"
                    :data-tippy-content="$lng.__('lk_webmaster_dashboard', 'Conversion dynamics')"
                ></div>
            </div>

            <div class="DashboardChart__picker">
                <DateRangePicker
                    :label="$lng.__('lk_webmaster_dashboard', 'Date')"
                    ref="picker"
                    opens="left"
                    :locale-data="{
                        firstDay: 1,
                        format: 'dd.mm.yyyy',
                        daysOfWeek: $lng.__('lk_webmaster_main', 'Sun,Mon,Tue,Wed,Thu,Fri,Sat').split(','),
                        monthNames: $lng.__('lk_webmaster_main', 'Jan,Feb,Mar,Apr,May,Jun,Jul,Aug,Sep,Oct,Nov,Dec').split(','),
                    }"
                    :singleDatePicker=false
                    :timePicker=false
                    :showDropdowns=true
                    :autoApply=true
                    :dateRange=dateRange
                    v-model=dateRange
                    @update="setDateRange"
                    :linkedCalendars=false
                    :maxDate="new Date()"
                ></DateRangePicker>
            </div>

        </div>

        <Loading v-if="isLoading"></Loading>

        <div v-show="!isLoading" class="dashboard__lead-statistics_chart">
            <div class="dashboard__chart-legend">
                <div class="dashboard__chart-confirmed">
                {{ $lng.__('lk_webmaster_dashboard', 'Confirmed') }}
                </div>
                <div class="dashboard__chart-conversions">
                {{ $lng.__('lk_webmaster_dashboard', 'Conversions') }}
                </div>
            </div>
            <canvas id="wv-chart" class="dashboard__lead-statistics_chart-canvas"></canvas>
        </div>
    </section>
</template>

<script>

import {Chart} from 'chart.js';
import tippy from 'tippy.js';
import DateRangePicker from '@/components/DateRangePicker/components/DateRangePicker';

export default {
    name: 'DashboardChart',
    components: {DateRangePicker},
    props: [],
    data() {
        return {
            isVisible: false,
            isLoading: false,
            dateRange: {
                startDate: (new Date).addDays(-14),
                endDate: (new Date)
            },
            chart: false,
        };
    },
    computed: {
        stat() {
            return this.$store.state.webmaster.graphLeadStat;
        },
        chartLabels() {
            return this.stat.valid.map(item => {
                return item.timestamp;
            });
        },
        valuesValid() {
            return this.stat.valid.map(item => {
                return item.value;
            });
        },
        valuesConfirmed() {
            return this.stat.confirmed.map(item => {
                return item.value;
            });
        },
    },
    watch: {
        dateRange: {
            handler(val, oldVal) {
                this.getStat();
            },
            deep: true,
        }
    },
    methods: {
        makeChart() {
            window.chart ? window.chart.destroy() : false;

            const ctx = document.getElementById('wv-chart');
            if (!ctx) return;

        window.chart = new Chart(ctx, {
          type: 'line',
          borderWidth: 4,
          data: {
            labels: this.chartLabels,
            datasets: [
              {
                label: 'Conversions',
                data: this.valuesValid,
                backgroundColor: ['rgba(109, 175, 253, 0.09)'],
                borderColor: ['rgba(109, 175, 253, 1)'],
                order: 1,
              },
              {
                label: 'Confirmed',
                data: this.valuesConfirmed,
                backgroundColor: ['rgba(0, 216, 86, 0.09)'],
                borderColor: ['rgba(0, 197, 79, 1)'],
                order: 2,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            },
            plugins: {
              legend: false,
            },
          },
        });
      },
      getStat() {
        this.isLoading = true;
        this.$store
          .dispatch('webmaster/getGraphLeadStat', {
            from: this.dateRange.startDate.getFormattedDate() + ' 00:00:00',
            to: this.dateRange.endDate.getFormattedDate() + ' 23:59:59',
          })
          .then(() => {
            this.isLoading = false;
            this.makeChart();
          });
      },
      setDateRange(dateRange) {
        this.dateRange = dateRange;
      },
    },
    beforeMount() {
    },
    mounted() {
        this.isVisible = true;
        this.getStat();
        setTimeout(WV.tippyMount, 3000);
    }
};
</script>

<style lang="scss">

@import '../../../scss/utils/mixins.scss';
@import '../../../scss/utils/variables.scss';

.DashboardChart {
    &__head {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        @include break(md) {
            flex-direction: row;
        }
        &__title {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }
    &__picker {
        margin-top: 15px;
        width: 100%;
        @include break(md) {
            margin-top: 0;
            max-width: 230px;
        }
    }
}

.dashboard__lead-statistics {
    &-block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 15px;
        order: 3;
        @include grid--100;
        @include break(md) {
            padding: 30px;
        }
    }

    &_title {
        margin-bottom: 30px;
        font-family: $font-primary;
        font-weight: 800;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: 0.005em;
        @include maxWidth(950) {
            font-size: 18px;
            line-height: 22px;
        }
    }

    &_chart {
        width: 100%;
        max-height: 100%;
        margin-top: 20px;
        overflow: hidden;
        @include maxWidth(950) {
            padding: 0;
        }
        &-canvas {
            width: 100%;
            height: 300px !important;
        }
    }
  }
  .dashboard__chart-legend {
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-family: $font-primary;
    @include break(md) {
      flex-direction: row;
    }
    .dashboard__chart-confirmed {
      position: relative;
      padding: 5px 20px 5px 60px;
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translate(0, -50%);
        width: 40px;
        height: 4px;
        background: rgba(0, 197, 79, 1);
        border-radius: 2px;
      }
    }
    .dashboard__chart-conversions {
      position: relative;
      padding: 5px 20px 5px 60px;
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translate(0, -50%);
        width: 40px;
        height: 4px;
        background: rgba(109, 175, 253, 1);
        border-radius: 2px;
      }
    }
  }
</style>