<template>
    <section class="wv-block--white-hover dashboard-news">
        <swiper
            :slidesPerView="1"
            :spaceBetween="20"
            :loop="true"
            :grabCursor="true"
            :autoplay="{
        delay: 12000,
        disableOnInteraction: false,
      }"
        >
            <swiper-slide v-for="(newsItem, index) in news" :key="newsItem.id">
                <div
                    class="dashboard-news-slide"
                    :style="{ backgroundImage: 'url(/images/' + newsItem.image + ')' }"
                >
                    <button
                        class="dashboard-news-button wv-btn--green"
                        @click="openModal(index)"
                    >
                        All news
                    </button>
                </div>
            </swiper-slide>
        </swiper>

    </section>


    <!-- MODAL -->
    <Modal title=" " :class="news_modal" v-if="showModal" @close="closeModal">
        <div class="news-modal-scroll">
            <swiper
                :initialSlide="activeSlide"
                :slidesPerView="1"
                :spaceBetween="30"
                :loop="true"
                :grabCursor="true"
                :autoHeight="true"
                :navigation="{
          prevEl: '.news-swiper-button .prevSlide',
          nextEl: '.news-swiper-button .nextSlide',
        }"
            >
                <swiper-slide v-for="newsItem in news" :key="newsItem.id">
                    <div class="news-image">
                        <img :src="'/images/' + newsItem.image" alt="News image"/>
                    </div>
                    <div class="news-content">
                        <div class="news-title">
                            <h4>{{ newsItem.title }}</h4>
                            <p>{{ newsItem.date }}</p>
                        </div>
                        <div v-html="newsItem.full_description"></div>
                    </div>
                </swiper-slide>
            </swiper>
        </div>
        <div class="news-swiper-button">
            <div class="prevSlide">
                <svg
                    width="24"
                    height="72"
                    viewBox="0 0 24 72"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M22.2939 1L1.70571 36L22.2939 71"
                        stroke="#E1E6EB"
                        stroke-width="2"
                        stroke-linecap="round"
                    />
                </svg>
            </div>
            <div class="nextSlide">
                <svg
                    width="24"
                    height="72"
                    viewBox="0 0 24 72"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M1.70605 1L22.2943 36L1.70605 71"
                        stroke="#E1E6EB"
                        stroke-width="2"
                        stroke-linecap="round"
                    />
                </svg>
            </div>
        </div>
    </Modal>

</template>

<script>
// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.min.css';
import SwiperCore, {Autoplay, Navigation} from 'swiper/core';

SwiperCore.use([Autoplay, Navigation]);

import Modal from '@/components/Modal.vue';

export default {
    name: 'DashboardNews',
    components: {Modal, Swiper, SwiperSlide},
    data() {
        return {
            newsLimit: 3,
            showModal: false,
            activeSlide: 0,
        };
    },
    methods: {
        openModal(index) {
            this.activeSlide = index;
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
        },
    },
    beforeMount() {
        this.$store.dispatch('webmaster/getNews');
    },
    computed: {
        news() {
            return this.$store.getters['webmaster/dashboardNews'];
        },
    },
};
</script>

<style lang="scss">
@import '../../../scss/utils/mixins.scss';
@import '../../../scss/utils/variables.scss';

.ui_modal_overlay {
    background: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(5px);
}
.news-modal-scroll {
    max-height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    @extend .noScrollbar;

}
.ui_modal_title {
    padding: 0;
    border: none;
}
.hide-news {
    display: none;
}
.news-content {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.5px;
    color: #0d0d0d;
    p{
        padding-bottom: 15px;
    }
}
.news-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: $font-secondary;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    letter-spacing: 0.005em;
    text-transform: uppercase;
    color: #a3b7ca;
    padding: 30px 0 15px;
    h4 {
        font-family: $font-primary;
        font-weight: bold;
        text-align: left;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: 0.005em;
        color: #0d0d0d;
    }
}
.news-image {
    width: 100%;
    img {
        display: block;
        margin: 0 auto;
        min-width: 100%;
    }
}
.news-swiper-button {
    z-index: 1;
    width: calc(100% + 50%);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .prevSlide,
    .nextSlide {
        cursor: pointer;
        width: 86px;
        height: 86px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
    }
    .prevSlide:hover,
    .nextSlide:hover {
        background: rgba(255, 255, 255, 0.335);
        backdrop-filter: blur(5px);
        path {
            stroke: black;
        }
    }
    @include maxWidth(846) {
        width: calc(100% + 180px);
    }
    @include maxWidth(660) {
        display: none;
    }
}
.observer {
    width: 100%;
    height: 1px;
    visibility: hidden;
}


.dashboard-news {
    box-sizing: border-box;
    width: 100%;
    margin: 0 auto 20px;
    overflow: hidden;
    order: 2;
    @include break(md) {
        order: 2;
        width: calc(50% - 10px);
        margin: 0 10px 20px 0;
    }
    @include break(xxl) {
        order: 4;
        width: calc(35% - 20px);
        margin: 0;
    }
}
.dashboard-news-slide {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 300px;
    padding: 30px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    cursor: grab;
    @include break(sm) {
        height: 350px;
    }
    @include break(xxl) {
        height: 500px;
    }
    .dashboard-news-button {
        background: rgb(255, 255, 255);
        width: fit-content;
        padding: 16px 30px;
    }
}



</style>
